.portfolioHeading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.projectContainer {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
}

.projectCard {
  background: #1a1919;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
}

.projectCard img {
  width: 100%;

}

.projectTitle {
  color: #fff;
  padding: 1rem 0;
}

.projectDetails {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: justify;
}

.projectDetails p {
  padding-bottom: 1rem;
}

.projectBtns {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-top: auto;
}

.projectBtns .btn {
  padding: 0.5rem 1rem;
}

.btnHidden {
  display: none;
}

#react-modal-image-img {
  max-width: 50%;
  max-height: inherit;
}

@media screen and (max-width: 740px) {
  .projectContainer {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }

    #react-modal-image-img {
      max-width: 100%;
      max-height: inherit;
    }
  
}
