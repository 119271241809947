.specialtyContainer {
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 15px;
  padding: 2rem 0;
}

.specialSkills {
  max-width: 500px;
  background: #1a1919;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.specialTitle {
  color: #fff;
  padding: 1rem 0;
}

.specialDetails {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: center;
  text-justify: inter-word;
}

@media screen and (max-width: 640px) {
  .specialtyContainer {
      max-width: 500px;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem 1rem;
    }
  .specialSkills {
    max-width: 400px;
}
    
}
