.music-img {
  border-radius: 50%;
  object-fit: cover;
  height: 110px;
  width: inherit;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: rotate 3s linear infinite;

  animation-play-state: paused;
}

.play .img-container img {
  animation-play-state: running;
}

.play .music-info {
  opacity: 1;
  transform: translateY(-100%);
}

.music-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}

.action-btn {
  background-color: #fff;
  border: 0;
  color: #555;
  font-size: 20px;
  cursor: pointer;
  padding: 10px;
  margin: 0 20px;
}

.action-btn:focus {
  outline: 0;
}

.action-btn-big {
  color: #333;
  font-size: 30px;
}

.music-info {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 15px 15px 0 0;
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  max-width: 400px;
  padding: 10px 10px 10px 150px;
  opacity: 0;
  transform: translateY(0%);
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  z-index: 0;
}

.audio-player {
  max-width: 350px;
  width: 350px;
  border-radius: 20px;
  padding: 24px;
  box-shadow: 0 28px 28px rgba(0, 0, 0, 0.2);
  margin: auto;
  color: white;
  background-color: #222;
}

.artwork {
  border-radius: 120px;
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
}

.track-info {
  text-align: center;
  z-index: 1;
  position: relative;
}

.title {
  margin-top: 10px;
  font-weight: 700;
  margin-bottom: 4px;
}

.artist {
  font-weight: 300;
  margin-top: 0;
}

.audio-controls {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 10px auto 15px;
}

button {
  background: none;
  border: none;
}

.container {
  padding: 5rem;
  margin: auto;
  display: flex;
  justify-content: center;
}
