@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

body {
  background: #000;
}

h1, h4, p, a {
  color: #fff;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.btn {
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  background: #fff158;
  color: #222;
  border: 1px solid #fff;
  font-weight: 600;
  cursor: pointer;
}

.btnLight {
  background: transparent;
  color: #fff;
}

.btn:hover {
  background: rgba(248, 217, 15, 0.2);
  color: #fff;
  transition: 0.3s;
}