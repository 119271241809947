.about {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
}

.about .left {
  text-align: center;
  margin: auto;
  padding: 1rem;
  max-width: 400px;
}

.about .left p {
  margin: 1.2rem 0;
  text-align: justify;
  text-justify: inter-word;
}

.about .right {
  max-width: 700Px;
}

.left .imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding-top: 2rem;
}

.left .certImg {
  width: 75px;
  height: 75px;
  margin: 0 1rem;
}

.right .aboutImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.right .img {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  margin: auto;
}



