.heroContentImg {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 70vh;
  position: relative;
}

.heroContentImg::before {
  content: '';
  background: url('../../assets/wavesHeroContent.jpg');
  background-size: cover;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroContentImg h1 {
  font-size: 2.4rem;
  text-align: center;
}

.heroContentImg p {
  font-size: 1.4rem;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .heroContentImg h1 {
      font-size: 2rem;
    }
  .heroContentImg p {
      font-size: 1rem;
      text-align: center;
    }
}