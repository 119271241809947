.footer {
  width: 100%;
  background-color: rgba(19,19,19, 0.4);
}

.footerContainer {
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footerContent {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.social {
  padding-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 640px) {
  .footerContainer {
    grid-template-columns: 1fr;
  }
}