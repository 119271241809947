.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 10;
}

.headerBG {
  background-color: rgba(0, 0, 0, 0.6);
  transition: 1s;
}

.navMenu{
  display: flex;
}

.navMenu li {
  padding: 0 1rem;  
}

.navMenu li a {
  font-size: 1.2rem;
  font-weight: 500;
  transition: all 0.5s ease-in-out;
}

.navMenu li a:hover {
  color: #fff158;
}

.homeIcon {
  width: 75px;
}

.homeIcon:hover {
  transform: scale(1.2);
  transition: all 1s ease-out;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 640px) {
  .navMenu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.3s;
  }

  .navMenu.active {
    left: 0;
  }

  .navMenu li {
    padding: 1rem 0;
  }
  .navMenu li a {
    font-size: 2rem;
  } 
 .hamburger {
    display: initial;
  }
}